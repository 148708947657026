.c-manage-team-settings {
    align-self: center;
    max-width: rem(668px);
    margin-top: rem(24px);
    margin-bottom: rem(24px);
    @include respond-to-min-width("desktop") {
        width: rem(668px);
    }

    &__card {
        @include padding-left(16px);
        @include padding-right(16px);
        @include padding-top(11px);
        @include padding-bottom(16px);
        @include margin-bottom(24px);
        border-radius: $border-radius-medium;
        border: 1px solid get-color-neutral("30");
        height: rem(289px);
        overflow-y: auto;
        background-color: white;
    }

    &__content {
        &__email {
            @include margin-bottom(64px);
            display: flex;
            align-items: center;

            &.-invalid {
                @include respond-to-min-width("desktop") {
                    .c-button {
                        margin-top: rem(-16px);
                    }
                }
            }

            .c-form-field {
                flex-basis: 100%;
                margin-bottom: 0;
                position: relative;

                input {
                    margin-top: 0;
                }

                &__bottom {
                    position: absolute;
                }
            }

            button {
                @include margin-left(16px);
                @include margin-top(4px);
                flex-shrink: 0;
            }

            textarea {
                @include padding(12px);
                resize: none;

                @include respond-to("phone") {
                    @include padding(16px, 16px, 32px);
                    margin-top: 0;
                }
            }

            @include respond-to("phone") {
                margin-bottom: rem(48px);
            }
        }

        @include respond-to("tablet") {
            margin-bottom: rem(68px);
        }
    }

    &__contact-us-message {
        @include padding-top(24px);
        display: flex;
        justify-content: center;
    }

    &__not-enough-seats-error {
        @include padding-bottom(16px);
        max-width: rem(284px);
        justify-self: center;
    }

    &__pending {
        &__actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__forward {
            display: flex;
            gap: rem(16px);
        }

        &__title {
            @include padding-bottom(4px);
            @include padding-left(16px);
            @include font-style(
                $font-primary,
                "base",
                get-color-neutral("90"),
                400,
                get-line-height("base")
            );
            @include box-shadow(0px, 1px, 0px, get-color-neutral("30"));
            letter-spacing: rem(0.32px);
        }
        &__content {
            &__row {
                @include padding(16px);
                @include font-style(
                    $font-primary,
                    "small",
                    get-color-neutral("90"),
                    400,
                    get-line-height("small")
                );
                display: flex;
                align-items: center;
                letter-spacing: 0.02em;

                @include respond-to("phone") {
                    @include padding(16px, 0);
                }

                span:first-of-type {
                    flex-basis: 70%;

                    @include respond-to("tablet") {
                        flex-basis: initial;
                    }
                }

                &:hover {
                    color: get-color-neutral("70");
                    background-color: get-color-neutral("05");
                }

                &:not(:last-of-type) {
                    @include box-shadow(0px, 1px, 0px, get-color-neutral("30"));
                }

                &__actions {
                    @include font-style(
                        $font-primary,
                        "xsmall",
                        get-color-neutral("70"),
                        400,
                        get-line-height("xsmall")
                    );
                    margin-left: auto;
                    letter-spacing: 0.02em;
                    font-style: italic;
                    text-align: right;
                    display: flex;
                    flex-shrink: 0;
                    align-items: center;

                    @include respond-to("tablet") {
                        flex-shrink: initial;
                        flex-wrap: wrap;
                        justify-content: flex-end;
                    }
                    @include respond-to("phone") {
                        flex-direction: column;
                        margin-left: 0;
                        margin-top: rem(8px);
                        text-align: left;
                    }

                    span:last-of-type {
                        font-weight: 700;
                    }
                    button {
                        @include margin-left(16px);

                        @include respond-to("phone") {
                            @include margin-top(4px);
                            margin-left: 0;
                        }
                    }

                    &__buttons {
                        > button {
                            &:first-of-type {
                                margin-right: rem(5px);
                            }
                        }
                    }

                    &__info {
                        @include respond-to("phone") {
                            align-self: normal;
                            margin-bottom: rem(8px);
                        }
                    }
                }

                &__email {
                    @include respond-to("tablet") {
                        display: inline-block;
                        max-width: rem(192px);
                    }
                }
            }
        }
    }

    &__removed-content-row-email {
        text-decoration: line-through;
        @include respond-to("tablet") {
            display: inline-block;
            max-width: rem(192px);
            text-decoration: line-through;
        }
    }

    @include respond-to("phone") {
        @include padding(24px);

        &__content {
            margin-bottom: rem(32px);

            &__email {
                flex-wrap: wrap;
                button {
                    @include margin(16px, 0, 0);
                    margin-left: 0;
                    margin-top: rem(82px);
                }

                .c-form-field__bottom {
                    position: static;
                }
            }
        }
        &__pending__content {
            &__row {
                flex-wrap: wrap;
                span:first-of-type {
                    flex-basis: 100%;
                }
            }
        }
    }
}
