.c-verify-team-info {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    align-items: center;
    gap: rem(24px);

    &__title {
        @include font-style(
            $font-primary,
            "base",
            get-color-neutral("90"),
            400,
            24px
        );
        @include padding(24px, 24px, 8px, 24px);
        text-align: center;
        letter-spacing: 0.02em;
    }

    &__input-container {
        width: rem(416px);
        height: rem(129px);
        background-color: get-color-neutral("white");
        @include padding(16px, 24px, 16px, 24px);
        @include border-radius(large);
        @include border(1px, 1px, 1px, 1px, solid, get-color-neutral("30"));

        label {
            @include font-style(
                $font-primary,
                "xxsmall",
                get-color-neutral("70"),
                700,
                16px
            );
            letter-spacing: 0.11em;
        }

        select {
            @include font-style(
                $font-primary,
                "small",
                get-color-neutral("70"),
                400,
                16px
            );
            letter-spacing: 0.02em;

            option {
                @include font-style(
                    $font-primary,
                    "small",
                    get-color-neutral("70"),
                    400,
                    16px
                );
                letter-spacing: 0.02em;
            }
        }

        input {
            @include font-style(
                $font-primary,
                "xxsmall",
                get-color-neutral("70"),
                400,
                16px
            );
        }
    }
}
