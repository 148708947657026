.c-stepper {
    @include padding(16px, 0, 16px, 0);
    @include font-style(
        $font-primary,
        "xsmall",
        false,
        400,
        get-line-height("small")
    );
    display: flex;
    flex-direction: row;
    gap: rem(24px);

    &__step {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        position: relative;
        gap: rem(8px);
        opacity: 0.5;

        &__number {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: get-color-neutral("30");
            width: rem(20px);
            height: rem(20px);
        }

        &.-active {
            opacity: 1;
        }
    }
}
