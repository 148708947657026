.c-reference-panel-button {
    background: get-color-neutral("white");
    height: 100%;

    @include if-internet-explorer() {
        top: 0;
    }

    @include respond-to("lg-tablet") {
        top: rem(-10px);
    }

    &__counter {
        @include font-size("xxsmall");
        @include padding(4px);
        background: get-color-neutral("05");
        border-radius: rem(10px);
        color: get-color-neutral("70");
        display: flex;
        align-items: center;
        height: rem(16px);
        z-index: 2;

        &.-has-references {
            color: get-color-neutral("white");
            background: get-color-neutral("70");
        }
    }

    &__icon {
        svg {
            z-index: 2;
        }
    }

    .c-button {
        @include padding-left(0);
        @include padding-right(0);
        @include padding-bottom(5.5px);
        background-color: transparent;
        border-radius: 0;
        border-radius: unset;
        border: none;
        display: flex;
        align-items: center;
        height: 40px;
        position: relative;
        align-items: center;

        @include respond-to("phone") {
            @include padding-left(8px);
            @include padding-right(8px);
        }

        &.-offline {
            cursor: not-allowed;
        }

        &.-panel-open {
            @include icon-fill(get-color-accents("blue-dark"));

            &::after {
                content: "";
                position: absolute;
                background-color: get-color-accents("blue-light");
                width: 50px;
                height: 100%;
                right: 0;
                top: 0;
                z-index: 1;
            }

            .c-reference-panel-button__counter {
                color: get-color-neutral("white");
                background: get-color-accents("blue-dark");
            }
        }
    }

    &__tooltip {
        &__title {
            @include font-style(
                $size: "small",
                $color: get-color-neutral("white"),
                $weight: "bold",
                $line-height: get-line-height("small")
            );
        }

        &__description {
            @include font-style(
                $size: "xsmall",
                $color: get-color-neutral("white"),
                $line-height: get-line-height("xsmall")
            );
        }
    }
}
