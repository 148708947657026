.c-customize-experience {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: space-between;
    @include padding(24px, 0, 0);
    gap: rem(24px);

    &__header {
        max-width: rem(688px);
        text-align: center;
        gap: rem(8px);
        @include respond-to("phone") {
            @include font-style($font-primary, "small", false, 400, 21px);
        }
    }

    &__topics-list {
        text-align: center;
        max-width: rem(828px);
        @include respond-to("phone") {
            fieldset {
                @include padding(0, 16px);
            }
            .c-checkbox-button {
                @include margin-left(8px);
                @include margin-right(8px);
            }
        }
    }

    &__publications {
        text-align: center;
        @include respond-to("phone") {
            @include padding-left(36px);
            display: block;
            margin: 0;
            width: calc(100% + 64px);
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            gap: rem(24px);
            max-width: rem(970px);
            flex-direction: row;
            justify-content: center;

            @include respond-to("phone") {
                display: block;
                position: relative;
                height: rem(190px);
                left: rem(-32px);
                max-width: none;
                overflow-x: scroll;
                overflow-y: hidden;
                scrollbar-width: none; /* Firefox */
                transition: all 0.2s;
                user-select: none;
                white-space: nowrap;
            }
        }
    }

    &__button-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        max-width: rem(688px);

        &__continue-button {
            align-self: flex-end;
        }

        &__back-button {
            align-self: flex-start;
        }
    }

    .c-customize-experience__button-container:has(:only-child) {
        justify-content: flex-end;
    }

    &__dots-container {
        display: flex;
        flex-direction: row;
        gap: rem(8px);
        align-items: center;
        justify-content: center;
    }

    &__dot {
        width: rem(12px);
        height: rem(12px);
        background-color: get-color-neutral("30");
        border-radius: 50%;

        &.-active {
            background-color: get-color-neutral("90");
        }
    }
}
