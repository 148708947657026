.c-change-indicator-legend {
    border-radius: 16px;
    @include padding(4px);

    @include respond-to("lg-tablet") {
        border-left: 1px solid get-color-neutral("30");
    }

    // undo height for when the class is on the mobile modal
    &.c-modal {
        height: unset;

        .c-modal__content {
            @include margin-bottom(24px);
        }

        .c-menu-button__item {
            margin-bottom: 0;
        }
    }

    .c-menu-button {
        &__item {
            background-color: transparent;
        }
    }

    &__menu-item-wrapper {
        @include padding(8px, 12px);
        align-self: stretch;

        letter-spacing: 0.24px;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__menu-text {
        display: flex;
        word-wrap: break-word; /* Allow the text to break and wrap */
        overflow-wrap: break-word; /* Same as word-wrap, for better cross-browser support */
        white-space: normal;
        @include font-style(
            $font-primary,
            "xsmall",
            get-color-neutral("90"),
            400,
            get-line-height("base")
        );
        flex: 1 0 0;
        align-items: center;
        min-height: 24px;
    }

    &__text-display {
        display: flex;
        height: rem(12px);
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        gap: rem(10px);
        background-color: get-color-status("warning-light");
    }

    &__icon-display {
        @include padding(4px);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: rem(10px);
        border-radius: 16px;
        background-color: get-color-status("warning-light");
        color: get-color-status("warning-dark");
    }

    .c-menu-button {
        &__icon {
            display: flex;
            position: relative;
            width: rem(24px);
            height: rem(24px);
            border: none;
            align-items: center;
            background-color: get-color-status("warning-light");
            border-radius: 16px;
            svg {
                path {
                    fill: get-color-status("warning-dark");
                }
            }
        }

        &__menu {
            top: rem(48px);
            right: rem(16px);
            min-width: rem(200px);

            &__title {
                @include padding(12px);
                @include padding-bottom(16px);
                @include font-style(
                    $color: get-color-neutral("70"),
                    $size: "xxsmall",
                    $line-height: "xxsmall",
                    $weight: "bold"
                );
                letter-spacing: 0.11em;
                text-transform: uppercase;
            }

            &__footer {
                @include margin-top(8px);
            }
        }
    }
}
