.c-team-onboarding-layout {
    $menu-height: 80px;
    $texture-height: 16px;
    display: flex;
    flex-direction: column;
    min-height: #{vh()};
    position: relative;
    width: 100%;
    background-color: get-color-neutral("05");
    gap: 10px;

    &.-emulation-mode {
        min-height: calc(100% - #{$emulation-banner-height});
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 100%;
        @include padding(48px);

        > div {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            height: 100%;
        }

        &__header {
            @include font-style(
                $font-primary,
                "xxlarge",
                get-color-neutral("90"),
                300,
                32px
            );
            @include padding(24px, 24px, 8px, 24px);
            display: flex;
            align-self: center;
            letter-spacing: 0.02em;

            @include respond-to("phone") {
                @include font-style($font-primary, "large", false, 500, 21px);
            }
        }

        &__stepper {
            align-self: center;
        }
    }

    .c-unauthenticated-footer {
        flex-shrink: 0;
    }

    .c-profile-menu {
        @include margin-left(16px);
    }

    @include respond-to-min-width("phone") {
        .c-profile-menu {
            &__trigger + div {
                position: absolute;
            }
            &__menu {
                position: relative;
                top: rem(12px);
                left: rem(-164px);
            }
        }
    }

    &__menu {
        @include padding(20px, 40px, 20px, 48px);
        position: relative;
        flex-shrink: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        height: $menu-height;
        width: 100%;
        background-color: get-color-neutral("white");
        box-shadow: 0px 3px 10px rgba(0, 26, 57, 0.2);

        @include respond-to("phone") {
            @include padding(14px, 16px, 14px, 16px);
            height: $menu-height - 24px;
        }

        &:after {
            height: $texture-height;
            width: 100%;
            background-image: url("../../../images/texture_diagonal-lines_gray.png");
            background-repeat: repeat;
            content: "";
            position: absolute;
            bottom: -$texture-height;
            left: 0;
            right: 0;
        }

        &__logo {
            img {
                height: 48px;
                width: auto;

                @include respond-to("phone") {
                    height: 32px;
                    width: auto;
                }
            }
        }

        &__auth {
            display: flex;
            align-items: center;
            margin-left: auto;

            @include respond-to("phone") {
                p,
                span {
                    @include font-style(
                        $size: "xsmall",
                        $line-height: 14px // intended
                    );
                }
            }

            &__name {
                display: flex;
                flex-direction: column;
                text-align: right;

                @include respond-to("phone") {
                    strong {
                        max-width: rem(120px);
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
            }

            &__link {
                @include margin-left(16px);

                @include respond-to("phone") {
                    @include padding(7px, 16px);
                    @include font-style(
                        $font-primary,
                        "xxsmall",
                        #ffffff,
                        800,
                        12px
                    );

                    &.-icon-left {
                        @include padding(5px, 12px, 5px, 7px);

                        .c-icon {
                            @include margin-right(4px);
                            vertical-align: middle;
                        }
                    }
                }
            }

            span {
                @include paragraph-styles("small");

                @include respond-to("phone") {
                    @include paragraph-styles("xsmall");
                }
            }
        }
    }
}
