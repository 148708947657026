.c-natural-language-search-modal {
    $footer-height: rem(68px);

    @include padding-top(12px);
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;

    &.-dirty {
        @include respond-to("tablet") {
            height: 100%;
        }
    }

    @include respond-to("tablet") {
        height: 100%;
    }

    &__content {
        @include padding(24px, 16px, 16px);
        display: flex;
        height: calc(100% - #{$footer-height});
        overflow-y: auto;

        @include respond-to("phone") {
            @include padding-top(8px);
        }
    }

    &__footer {
        @include padding(8px, 16px);
        @include box-shadow(0px, 1px, 10px, rgba(0, 26, 57, 0.2));

        @include respond-to("phone") {
            .c-page-navigation-menu {
                padding: 0;
            }
        }
    }

    .c-natural-language-search-modal-results {
        @include if-internet-explorer() {
            height: calc(100vh - 270px);
            overflow-y: auto;
        }
    }

    &__root {
        display: none;

        @include respond-to("phone") {
            display: block;
        }
    }

    .c-search-form {
        @include margin-left(16px);
        @include margin-right(16px);
    }
}
