.c-profile-menu {
    position: relative;
    z-index: 2;

    &__offline-indicator {
        top: rem(-30px);
        left: rem(9px);
        overflow: visible;
        position: absolute;

        > svg {
            @include icon-fill(get-color-neutral("white"));
        }

        @include respond-to("phone") {
            top: rem(8px);
            left: rem(-36px);
        }
    }

    // Extra modifier to add specificity to override modal styling.
    &__mobile.-modal {
        padding: 0;
        display: flex;
        flex-direction: column;

        .c-button.-modal-close {
            @include margin(0, 36px, 36px, 36px);
            width: unset;

            @include if-internet-explorer {
                width: auto;
            }
        }
    }

    &__trigger {
        background-color: transparent;
    }

    &__menu {
        @include box-shadow(0px, 3px, 10px, rgba(0, 26, 57, 0.2));
        position: absolute;
        bottom: 24px;
        left: 64px;
        width: 216px;
        background: get-color-neutral("05");
        border: 1px solid get-color-neutral("30");
        box-sizing: border-box;
        border-radius: 5px;

        &__user-info {
            @include padding(16px);
            height: 50%;
            width: 100%;
            display: inline-flex;

            &__initials {
                @include margin-right(8px);
            }

            &__name {
                @include font-style(
                    $font-primary,
                    "xsmall",
                    get-color-neutral("90"),
                    700,
                    18px
                );
                word-break: break-word;
            }

            &__role-name {
                @include font-style(
                    $font-primary,
                    "xsmall",
                    get-color-neutral("90"),
                    400,
                    18px
                );
                max-height: rem(36px);
                overflow: hidden;
                word-break: break-word;
            }
        }

        &__actions {
            height: 50%;
            background-color: get-color-neutral("white");
            border-radius: 0 0 5px 5px;

            > *:last-child {
                border-top: 1px solid get-color-neutral("30");
            }

            .c-button,
            a {
                @include padding(8px, 16px);
                @include font-style(
                    $font-primary,
                    "xsmall",
                    get-color-neutral("70"),
                    400,
                    22px
                );
                text-decoration: none;
                width: 100%;
                text-align: left;
                white-space: nowrap;
                background: none;
                border-radius: 0;

                &:hover,
                &:focus {
                    background-color: get-color-neutral("05");
                    text-decoration: none;
                    color: get-color-neutral("90");
                }

                &:focus {
                    @include respond-to("phone") {
                        background-color: initial;
                        text-decoration: none;
                        color: get-color-neutral("70");
                        outline: none;
                    }

                    &:hover {
                        @include respond-to("phone") {
                            background-color: get-color-neutral("05");
                        }
                    }
                }
            }

            .c-notification-dot-icon {
                display: inline-block;
                position: relative;
                top: rem(-1px);
                left: rem(6px);
            }
        }
    }

    & .c-notification-dot-icon {
        &.-pulse {
            position: absolute;
            top: rem(-8px);
            right: 0;

            @include respond-to("phone") {
                left: rem(-8px);
                right: auto;
                top: 0;
            }
        }
    }

    @include respond-to("phone") {
        &__menu {
            width: 100%;
            position: unset;
            box-shadow: none;
            border: none;

            @include if-internet-explorer {
                position: static;
            }

            &__actions {
                @include padding(24px, 36px);

                .c-button {
                    @include font-style(
                        $font-primary,
                        "small",
                        false,
                        400,
                        21px
                    );
                    @include padding(16px);
                }
            }

            &__user-info {
                @include padding(24px, 36px);

                &__initials {
                    @include margin-right(16px);
                }

                &__name {
                    @include font-style(
                        $font-primary,
                        "large",
                        get-color-neutral("90"),
                        400,
                        27px
                    );
                }
            }
        }
    }
}
