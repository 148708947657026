.c-contact-us-message {
    &__anchor {
        @include font-style(
            $font-primary,
            "base",
            get-color-accents("blue-base"),
            400,
            16px
        );
        text-decoration: none;
    }
}
