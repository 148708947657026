.c-profile-avatar {
    @include font-style(
        $font-primary,
        "base",
        get-color-neutral("90"),
        400,
        18px
    );
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    height: rem(40px);
    width: rem(40px);
    border-radius: 50%;
    background-color: get-color-accents("blue-base");
    border: 2px solid get-color-accents("blue-dark");
    position: relative;
    overflow: hidden;

    svg {
        position: absolute;
        height: rem(40px);
        width: rem(40px);
        bottom: rem(-7px);
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);

        path {
            fill: get-color-accents("blue-dark");
        }
    }
}
