.c-offline-book-status-icon {
    position: absolute;
    z-index: 1;
    bottom: rem(24px);
    left: rem(24px);

    @include respond-to("phone") {
        bottom: rem(8px);
    }

    .c-icon {
        @include icon-fill(get-color-status("warning"));
    }

    &.-downloaded {
        .c-icon {
            @include icon-fill(get-color-accents("blue-base"));
        }
    }
}
