.c-natural-language-search-modal-results {
    @include padding(0, 8px, 8px, 8px);
    padding-right: 0;
    height: 100%;

    &__list {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__item {
        width: 100%;
        text-decoration: none;
        @include padding(8px, 8px, 8px, 8px);

        &__title {
            text-decoration: none;
        }

        &__body {
            @include margin(8px, 0);
            color: get-color-neutral("70");
        }

        &__breadcrumbs {
            color: get-color-neutral("70");
        }

        &:hover {
            @include border-radius("base");
            background-color: get-color-neutral("05");

            .c-natural-language-search-modal-results {
                &__item {
                    &__title {
                        text-decoration: underline;
                    }

                    &__body {
                        color: get-color-neutral("90");
                    }
                }
            }
        }
    }
}
