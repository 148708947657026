.c-manage-team-shortcut {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    @include padding-bottom(24px);

    &__fullName {
        @include font-style(
            $size: "large",
            $weight: "base",
            $line-height: "base"
        );
    }

    &__teamName {
        @include font-style(
            $size: "small",
            $weight: "base",
            $line-height: "small",
            $color: get-color-neutral("50"),
            $style: italic
        );
    }
    &__info {
        flex: 1 1 fit-content;
    }

    &__button {
        flex: 0 1 fit-content;
    }
}
